import { graphql, HeadFC, Link } from "gatsby"
import React, { useCallback, useState } from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import SEO from "../components/SEO"
import Contact from "../components/Contact"
import ArrowWhite from "../assets/img/arrow-right.webp"
import useSiteMetadata from "../hooks/useSiteMetadata"

const categories = [
  "All Services",
  "Web Application",
  "AI Application",
  "Mobile Application",
  "User Interface Design",
  "Website",
]

const budgetCategories = [
  "All Budget",
  "Less than $10,000",
  "$10,000 - $50,000",
  "$50,000 - $1,00,000",
  "More than $1,00,000",
]

const categoryWiseProjects = {
  [categories[0]]: [1, 2, 4, 28, 29, 20, 3, 13, 15, 31, 34, 36, 35, 38, 37, 39, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 17, 18, 19, 21, 22, 23, 24, 25, 26, 27, 30, 32, 33, 40],
  [categories[1]]: [1, 2, 4, 28, 29, 20, 3, 13, 15, 31, 34, 36, 35, 38, 37, 39, 6, 7, 9, 12, 16, 18, 19, 21, 30, 32, 33, 40],
  [categories[2]]: [1, 20, 2],
  [categories[3]]: [4, 5, 36, 34, 35, 28, 3, 1, 9, 16, 19, 20],
  [categories[4]]: [2, 4, 6, 7, 10, 11, 14, 15, 13, 12, 16, 17, 18, 19, 20, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 37, 38, 39],
  [categories[5]]: [8, 5, 10, 11, 14, 17, 22, 23, 24, 25, 26, 27, 28],
}

const budgetWiseProjects = {
  [budgetCategories[0]]: [1, 2, 4, 28, 29, 20, 3, 13, 15, 31, 34, 36, 35, 38, 37, 39, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 17, 18, 19, 21, 22, 23, 24, 25, 26, 27, 30, 32, 33, 40],
  [budgetCategories[1]]: [8, 10, 11, 14, 17, 22, 23, 24, 25, 26, 27],
  [budgetCategories[2]]: [4, 6, 7, 9, 12, 13, 15, 16, 18, 19, 20, 21, 24, 26, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
  [budgetCategories[3]]: [1, 2, 5],
  [budgetCategories[4]]: [1, 28],
}

const PortfolioPage = ({ data }) => {
  const {
    allPortfolioJson: { nodes: Portfolio },
  } = data
  const [allCategories] = useState(categories)
  const [allBudgetCategories] = useState(budgetCategories)
  const [activeCategory, setActiveCategory] = useState(allCategories[0])
  const [activeBudgetCategory, setActiveBudgetCategory] = useState("")
  const [categoryInfo] = useState(Portfolio)
  const [filteredProjectsNumber, setFilteredProjectsNumber] = useState<Array<number>>(categoryWiseProjects[categories[0]])

  const getActiveCategoryClass = useCallback(
    category => {
      if (category === activeCategory) {
        return `underline italic`
      }
      return ""
    },
    [activeCategory]
  )
  const getActiveBudgetCategoryClass = useCallback(
    category => {
      if (category === activeBudgetCategory) {
        return `underline italic`
      }
      return ""
    },
    [activeBudgetCategory]
  )

  const fieldBackgroundColor = (field: string) => {
    switch (field) {
      case 'Web App':
        return 'bg-[#FFF2E3]'
      case 'Mobile App':
        return 'bg-[#E7FFEF]'
      case 'AI':
        return 'bg-[#E8FBFF]'
      case 'UI':
        return 'bg-[#E8E3FF]'
      case 'Website':
        return 'bg-[#FFF5C6]'
    }
  }

  return (
    <>
      <section className="py-10 md:py-[7.5rem] lg:pb-16">
        <div className="container">
          <div className="tabbing w-full flex flex-wrap items-start gap-y-10">
            <div className="rounded-[0.625rem] border border-cream p-5 md:pb-3 w-full md:w-[16.5rem] md:sticky top-20 flex flex-wrap justify-between gap-y-6 md:block max-sm:hidden">
              <div className="max-[480px]:w-full w-[calc(50%-10px)] md:w-full">
                <h4 className="text-lg font-blancomedium pb-3">Services</h4>
                <ul className="md:flex md:flex-col hidden portfolio-sidebar">
                  {allCategories.map((category, idx) => (
                    <li
                      className={`cursor-pointer text-sm font-gorditaregular mb-2 transition-all duration-300 ${getActiveCategoryClass(
                        category
                      )}  hover:text-black `}
                      onClick={() => {
                        window.scrollTo(0, 0)
                        setActiveCategory(category)
                        setFilteredProjectsNumber(categoryWiseProjects[category])
                        setActiveBudgetCategory("")
                      }}
                      key={`${idx}- ${category}`}
                    >
                      <span className="relative before:content[''] before:absolute before:bg-transparent before:w-[3px] before:h-full before:bottom-0 before:top-0 before:-left-5 before:z-10 before:transition-all before:duration-200 hover:before:bg-[#FF3D2E] animate-[linear] overflow-visible">
                        {category}
                      </span>
                    </li>
                  ))}
                </ul>
                <select
                  aria-label="label for the category select"
                  onChange={event => {
                    setActiveCategory(event.target.value)
                    setFilteredProjectsNumber(categoryWiseProjects[event.target.value])
                    setActiveBudgetCategory("")
                  }}
                  value={activeCategory}
                  className="md:hidden block w-full border border-[#C8CBD2] rounded-md text-black py-3.5 px-5 font-gorditamedium text-[0.938rem] appearance-none bg-[url('../assets/img/down-arrow-orange.svg')] bg-no-repeat bg-[center_right_1.25rem] bg-[length:13px_20px]"
                >
                  {allCategories.map((category, idx) => (
                    <option key={`${idx}-${category}`} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="max-[480px]:w-full w-[calc(50%-10px)] md:w-full md:mt-10">
                <h4 className="text-lg font-blancomedium pb-3">Budget</h4>
                <ul className="md:flex md:flex-col hidden portfolio-sidebar">
                  {allBudgetCategories.map((budgetCategory, idx) => (
                    <li key={idx} className={`${getActiveBudgetCategoryClass(budgetCategory)} cursor-pointer text-sm font-gorditaregular mb-2 transition-all duration-300 hover:text-black`}>
                      <span
                        className="relative before:content[''] before:absolute before:bg-transparent before:w-[3px] before:h-full before:bottom-0 before:top-0 before:-left-5 before:z-10 before:transition-all before:duration-200 hover:before:bg-[#FF3D2E] animate-[linear] overflow-visible"
                        onClick={() => {
                          window.scrollTo(0, 0)
                          setActiveBudgetCategory(budgetCategory)
                          setFilteredProjectsNumber(budgetWiseProjects[budgetCategory])
                          setActiveCategory("")
                        }}
                      >
                        {budgetCategory}
                      </span>
                    </li>
                  ))}
                </ul>
                <select
                  onChange={event => {
                    setActiveBudgetCategory(event.target.value)
                    setFilteredProjectsNumber(budgetWiseProjects[event.target.value])
                    setActiveCategory("")
                  }}
                  value={activeBudgetCategory}
                  className="md:hidden block w-full border border-[#C8CBD2] rounded-md text-black py-3.5 px-5 font-gorditamedium text-[0.938rem] appearance-none bg-[url('../assets/img/down-arrow-orange.svg')] bg-no-repeat bg-[center_right_1.25rem] bg-[length:13px_20px]"
                >
                  {allBudgetCategories.map((budgetCategories, idx) => (
                    <option key={idx}>{budgetCategories}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="case-study-catgory flex flex-col md:w-[calc(100%-16.5rem)] md:pl-10 max-sm:pt-10">
              <div className="flex justify-between flex-wrap">
                {filteredProjectsNumber.map((projectNumber, idx) => {
                  let {
                    field,
                    slug,
                    featuredImage,
                    title,
                    descriptionForListings,
                    technologies,
                    country,
                    industry,
                    tags: { budget }
                  } = categoryInfo[projectNumber - 1]
                  // Project Number is the field in the projects data which starts from 1
                  // The project we want to include will be found at the index {[projectNumber - 1]}

                  featuredImage = getImage(featuredImage)
                  return (
                    <div
                      key={`${idx}-${title}`}
                      className="mb-10 flex flex-col max-w-full border border-[#979797] rounded-[0.625rem] max-[480px]:w-full w-[calc(50%-10px)] md:w-full overflow-hidden"
                    >
                      <Link to={`/portfolio${slug}/`} >
                        <div className="flex justify-between items-start md:items-center p-5 max-sm:pb-0 border border-[#EAEAEA] max-[767px]:flex-col">
                          <h2 className=" text-[20px] max-[767px]:leading-[28px] lg:text-2xl font-blancomedium md:max-w-[240px] lg:max-w-full mb-4 md:mb-0">
                            {title}
                          </h2>
                          {/* <div className="inline-flex items-center bg-[#F8F7F3] justify-start rounded-full pt-2 md:pt-3 pb-2 px-4 font-gorditamedium text-sm leading-none">
                            <StaticImage
                              src="../../assets/img/star.png"
                              alt=""
                              placeholder="blurred"
                            />
                            <span className="mr-2 ml-1 relative text-sm font-gorditaregular top-[2px]">5.0/5</span>
                            <StaticImage
                              src="../../assets/img/cluch.png"
                              alt=""
                              placeholder="blurred"
                            />
                          </div> */}
                        </div>
                        <div className="flex justify-between flex-wrap items-center">
                          <div className={`img-container lg:w-[55%] w-full`}>
                            <GatsbyImage className="w-full md:h-[335px]"
                              image={featuredImage}
                              alt={title}
                            />
                          </div>
                          <div className="lg:px-[1.875rem] w-full lg:w-[45%] py-6 px-3">
                            {field.map((tag, idx) => (
                              <h3 key={idx} className={`btch-big ${fieldBackgroundColor(tag)} inline-block py-[0.313rem] pb-[0.188rem] px-[1.5rem] rounded-[6.25rem] text-xs text-center italic font-gorditamedium leading-6 mb-3 mr-1`}>{tag}</h3>
                            ))}
                            <ul className="flex flex-wrap mb-4">
                              <li className="text-sm pr-3 mr-3 relative after:content-[''] after:w-1 after:h-1 after:bg-black after:rounded-full after:absolute after:top-[7px] after:right-[-2px]" >
                                {industry}
                              </li>
                              <li className="text-sm pr-3 mr-3 relative after:content-[''] after:w-1 after:h-1 after:bg-black after:rounded-full after:absolute after:top-[7px] after:right-[-2px]" >
                                {budget}
                              </li>
                              <li className="text-sm">
                                {country}
                              </li>
                            </ul>
                            <p className="leading-7 pb-3 font-gorditaregular">
                              {descriptionForListings.length > 100
                                ? descriptionForListings.substring(0, 100 - 3) + "..."
                                : descriptionForListings}
                            </p>
                            <ul className="flex flex-wrap">
                              {technologies.map(({ name }, idx) => {
                                return (
                                  <li
                                    key={`${idx}-${name}`}
                                    className="text-xs text-black pt-2 mt-2 font-gorditaregular px-3 py-2 bg-white-300 mr-2 rounded"
                                  >
                                    {name}
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section >
      <section className="pb-[6.875rem]">
        <div className="container">
          <div className="flex flex-wrap justify-between md:flex-nowrap md:pl-12 pl-[1.875rem] p-5 rounded-[1.25rem] bg-[#F8F7F3]">
            <div className="md:max-w-[calc(37%-2.5rem)] md:pr-0 pr-[1.875rem] md:mb-0 mb-[3.125rem] w-full flex flex-col">

              <div className="max-w-full w-full">
                <h3 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-left pb-5">
                  Our impressive <span className="text-[#E34880] italic">Dribbble</span> shots
                  <span className="inline-block text-orangecol">.</span>
                </h3>

                <p className="text-base leading-[1.875rem] block pb-5">
                  Our company offers personalized design and development solutions that prioritize user experience.
                </p>
                <Link
                  to={`https://dribbble.com/techuz`}
                  className="inline-flex items-center bg-white-200 justify-start inline-white text-black text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium group ease-in duration-300"
                >
                  Get Inspired
                  <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full ml-[0.625rem]  overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary relative">
                    <img
                      src={ArrowWhite}
                      alt=""
                      className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                    />
                    <img
                      src={ArrowWhite}
                      alt=""
                      className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="md:max-w-[600px] w-full bg-white-200 rounded-[1.25rem]">
              <StaticImage
                src="../assets/img/dribble-shot.webp"
                alt=""
                placeholder="blurred"
                className="rounded-[1.25rem]"
              />
            </div>
          </div>
        </div>
      </section>
      <Contact />
    </>
  )
}

export const portfolioQuery = graphql`
  query {
    allPortfolioJson {
      nodes {
        categories
        field
        slug
        description
        descriptionForListings
        country
        industry
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 70)
          }
        }
        featuredImageAlt
        technologies {
          name
        }
        title
        backgroundStyle
        tags {
          budget
        }
      }
    }
  }
`

export default PortfolioPage

export const Head: HeadFC = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <SEO
      title="Web and Mobile Application Work | Techuz InfoWeb"
      canonical={`${siteUrl}${location.pathname}`}
      description="We have developed various mobile and web applications for several industries using latest technology stack such as AngularJS, NodeJS, ReactJS, PHP, Laravel, iOS, Android, Object-C, Swift, MongoDB, My SQL and many more."
    />
  )
}
