import { HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ArrowWhite from "../assets/img/arrow-right.webp"
import ArrowBlack from "../assets/img/arrow-right-black.webp"
import Fresher from "../assets/img/fresher.inline.svg"
import Experienced from "../assets/img/experienced.inline.svg"
import Opening from "../assets/img/openings.inline.svg"
import Flexibility from "../assets/img/flexibility.inline.svg"
import PersonalBrand from "../assets/img/personal-brand.inline.svg"
import OpenOffice from "../assets/img/open-office.inline.svg"
import Hybrid from "../assets/img/hybrid.inline.svg"
import Training from "../assets/img/training.inline.svg"
import Beverages from "../assets/img/bevrages.inline.svg"

import Contact from "../components/Contact"
import SEO from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"

const CareerPage = () => {
  return (
    <>
      <section className="pt-[7.5rem] lg:pb-[6.875rem] pb-[4rem]">
        <div className="container">
          <div className="title-sec m-auto w-full max-w-[57.188rem] text-center pb-5">
            <h1 className="text-black text-[2.375rem] leading-[1] pb-5 lg:text-[3.25rem] text-center font-blancomedium">
              Tech<span className="text-primary">_</span>z is looking for <span className="text-primary">u</span>
              <span className="inline text-orangecol">.</span>
            </h1>
            <p className="text-[1.125rem] leading-[1.875rem] font-gorditaregular text-black text-center">
              Want to kick-start your career as a junior? Or move up the ladder
              as a veteran? Or maybe pivot to new technology or career path? We
              might have the right opportunity for you. Below are the open
              positions at Techuz. (In case you're wondering, it’s pronounced
              Tech-us.)
            </p>
          </div>
          <ul className="grid md:grid-cols-2 lg:grid-cols-3 gap-7 grid-cols-1 justify-center flex-wrap pt-10 max-w-[59.375rem] m-auto">
            <li className="flex flex-col items-start justify-start rounded-[1.25rem] bg-[#F8F7F2] p-[1.875rem] text-center">
              {/* <StaticImage
                src="../assets/img/fresher.inline.svg"
                alt="Fresher icon"
                placeholder="blurred"
              /> */}
              <Fresher />
              <h3 className="btch-big bg-[#E8E3FF] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mb-5 mt-7 leading-7">Fresher</h3>
              <p className="leading-[1.625rem] text-left pb-10">Get an understanding of the industry from the basics and build a stepping stone for your career.</p>
              <Link
                to="https://docs.google.com/forms/d/1Cc4w4WDMDm6QGfWwp4vtoLxo02QlnBh80MrtYTIcJ8A/viewform?edit_requested=true"
                className="inline-flex w-auto gap-5 items-center bg-white-200 justify-center md:justify-start border border-white shadow-1xl text-base pl-7 py-1 pr-[0.313rem] rounded-[6.25rem] font-gorditamedium group ease-in duration-300"
              >
                Submit Resume
                <span className="inline-flex bg-primary justify-center items-center rounded-full relative w-[2.125rem] h-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                  <img
                    src={ArrowWhite}
                    alt="ArrowRight"
                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                  />
                  <img
                    src={ArrowWhite}
                    alt="ArrowRight"
                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                  />
                </span>
              </Link>
            </li>
            <li className="flex flex-col items-start justify-start rounded-[1.25rem] bg-[#F8F7F2] p-[1.875rem] text-center">
              {/* <StaticImage
                src="../assets/img/experienced.inline.svg"
                alt="Experienced icon"
                placeholder="blurred"
              /> */}
              <Experienced />
              <h3 className="btch-big bg-[#FFDBCA] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mb-5 mt-7 leading-7">Experienced</h3>
              <p className="leading-[1.625rem] text-left pb-[4.125rem]">Showcase your years of experience and knowledge and step forward for your growth.</p>
              <Link
                to="https://docs.google.com/forms/d/1Cc4w4WDMDm6QGfWwp4vtoLxo02QlnBh80MrtYTIcJ8A/viewform?edit_requested=true"
                className="inline-flex w-auto gap-5 items-center bg-white-200 justify-center md:justify-start border border-white shadow-1xl text-base pl-7 py-1 pr-[0.313rem] rounded-[6.25rem] font-gorditamedium group ease-in duration-300"
              >
                Submit Resume
                <span className="inline-flex bg-primary justify-center items-center rounded-full relative w-[2.125rem] h-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                  <img
                    src={ArrowWhite}
                    alt="ArrowRight"
                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                  />
                  <img
                    src={ArrowWhite}
                    alt="ArrowRight"
                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                  />
                </span>
              </Link>
            </li>
            <li className="flex flex-col items-start justify-start rounded-[1.25rem] border border-cream p-[1.875rem] text-center">
              {/* <StaticImage
                src="../assets/img/openings.inline.svg"
                alt="Openings icon"
                placeholder="blurred"
              /> */}
              <Opening />
              <h3 className="btch-big bg-[#FFF5C6] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mb-5 mt-7 leading-7">Current Openings</h3>
              <p className="leading-[1.625rem] text-left pb-[4.125rem]">Shape your career with us and get access to top industry experts and professionals.</p>
              <Link
                to="/openings/"
                className="inline-flex w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-black shadow-1xl text-base pl-7 py-1 pr-[0.313rem] rounded-[6.25rem] font-gorditamedium group ease-in duration-300"
              >
                View All Openings
                <span className="inline-flex bg-primary justify-center items-center rounded-full relative w-[2.125rem] h-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                  <img
                    src={ArrowWhite}
                    alt="ArrowRight"
                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                  />
                  <img
                    src={ArrowWhite}
                    alt="ArrowRight"
                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                  />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <section className="md:pb-20 pb-10">
        <div className="container">
          <div className="title-sec w-full max-w-[59.375rem] m-auto pb-14">
            <h3 className="btch-big bg-[#FFF2E3] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-base text-center italic font-gorditamedium mb-7 leading-7">Techuz Family</h3>
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium">
              We’re a team you’d enjoy spending time with <span className="italic text-primary">(except for boring team meetings)</span>
              <span className="inline-block text-orangecol">.</span>
            </h2>
          </div>
          <div className="flex justify-between flex-wrap">
            <div className="w-full lg:overflow-x-hidden">
              <div className="flex justify-between flex-wrap md:flex-nowrap lg:w-full max-w-[71.25rem] gap-[30px]">
                <div className="lg:w-full lg:max-w-[calc(33.3%-0.938rem)] md:w-[22.5rem] w-full flex flex-col items-end">
                  <div className="rounded-[0.625rem] overflow-hidden md:mb-[1.875rem]">
                    <StaticImage
                      src="../assets/img/teamp.webp"
                      alt="Techuz family"
                      className="w-full"
                      loading="eager"
                      placeholder="blurred"
                    />
                  </div>
                  <div className="rounded-[0.625rem] overflow-hidden md:mb-[1.875rem] pt-[30px] md:pt-0">
                    <StaticImage
                      src="../assets/img/teamp1.webp"
                      alt="Techuz family"
                      className="w-full"
                      loading="eager"
                      placeholder="blurred"
                    />
                  </div>
                </div>
                <div className="lg:w-full lg:max-w-[calc(33.3%-0.938rem)] md:w-[22.5rem] w-full flex flex-col items-end">
                  <div className="rounded-[0.625rem] overflow-hidden w-full">
                    <StaticImage
                      src="../assets/img/Bitmap.webp"
                      alt="Techuz family"
                      className="w-full"
                      loading="eager"
                      placeholder="blurred"
                    />
                  </div>
                </div>
                <div className="lg:w-full lg:max-w-[calc(33.3%-0.938rem)] md:w-[22.5rem] w-full flex flex-col items-end justify-end">
                  <div className="rounded-[0.625rem] overflow-hidden mb-[1.875rem] w-full">
                    <StaticImage
                      src="../assets/img/teamp2.webp"
                      alt="Techuz family"
                      className="w-full"
                      loading="eager"
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:max-w-[calc(66.6%-0.938rem)] max-w-full ml-auto lg:mt-0">
              <div className="relative rounded-[0.625rem] overflow-hidden">
                <div className="rounded-[0.625rem] overflow-hidden w-full">
                  <StaticImage
                    src="../assets/img/teamp3.webp"
                    alt="Techuz family"
                    className="w-full"
                    loading="eager"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lg:pb-[6.875rem] pb-[4rem]">
        <div className="container">
          <div className="title-sec m-auto w-full max-w-[34.688rem] pb-10">

            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-5 font-blancomedium text-center">
              Looking for perks?
            </h2>
            <p className="leading-[1.7rem] text-center">
              If you scrolled to this section right away, we already like you.
            </p>
          </div>
          <ul className="flex justify-between flex-wrap">
            <li className="md:mb-[1.875rem] mb-5 w-full md:max-w-[calc(41.6%-0.938rem)] max-w-full lg:p-12 py-8 px-5 bg-[#F8F7F2] flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              {/* <StaticImage
                src="../assets/img/flexibility.inline.svg"
                alt="Flexibility icon"
                placeholder="blurred"
              /> */}
              <Flexibility />
              <h3 className="btch-big bg-[#E8E3FF] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mt-7 mb-4 leading-7">Work flexibility (And we mean it)</h3>
              <p className="leading-[1.625rem]">
                At Techuz, we prioritize people and productivity over policies. So we have thoughtfully created an environment that enables our team to do their finest work while also focusing on their well-being.
              </p>
            </li>
            <li className="md:mb-[1.875rem] mb-5 w-full md:max-w-[calc(58.3%-0.938rem)] max-w-full lg:p-12 py-8 px-5 border border-cream flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              {/* <StaticImage
                src="../assets/img/personal-brand.inline.svg"
                alt="Personal brand icon"
                placeholder="blurred"
              /> */}
              <PersonalBrand />
              <h3 className="btch-big bg-[#FFF2E3] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mt-7 mb-4 leading-7">Create your personal brand</h3>
              <p className="leading-[1.625rem]">
                Want to showcase your tech knowledge to the world? Want to build a personal brand? Well, we encourage you to write on our company blog (psst... it feels wonderful to get a byline under an article). And don’t worry if you’re not the most skilled writer; our content team will ensure your articles read like Hemingway.
              </p>
            </li>
            <li className="md:mb-[1.875rem] mb-5 w-full md:max-w-[calc(58.3%-0.938rem)] max-w-full lg:p-12 py-8 px-5 border border-cream flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              {/* <StaticImage
                src="../assets/img/open-office.inline.svg"
                alt="Open office icon"
                placeholder="blurred"
              /> */}
              <OpenOffice />
              <h3 className="btch-big bg-[#FFF5C6] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mt-7 mb-4 leading-7">Open office space</h3>
              <p className="leading-[1.625rem]">
                “Human beings weren’t meant to sit in little cubicles, staring at computer screens all day.” These words from Peter Gibbons (Office Space, 1999) hit us really hard. So we brought the change… at least for the former part. We replaced the cramped rows of cubicles with modern, aesthetically pleasing, and more social open office space.
              </p>
            </li>
            <li className="md:mb-[1.875rem] mb-5 w-full md:max-w-[calc(41.6%-0.938rem)] max-w-full lg:p-12 py-8 px-5 border border-cream flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              {/* <StaticImage
                src="../assets/img/hybrid.inline.svg"
                alt="Hybrid icon"
                placeholder="blurred"
              /> */}
              <Hybrid />
              <h3 className="btch-big bg-[#E8FBFF] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mt-7 mb-4 leading-7">Hybrid working model</h3>
              <p className="leading-[1.625rem]">
                We love our office, but we comply with our resources needs
              </p>
            </li>
            <li className="md:mb-0 mb-[1.875rem] w-full md:max-w-[calc(41.6%-0.938rem)] max-w-full lg:p-12 py-8 px-5 border border-cream flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              {/* <StaticImage
                src="../assets/img/training.inline.svg"
                alt="Training icon"
                placeholder="blurred"
              /> */}
              <Training />
              <h3 className="btch-big bg-[#E7FFEF] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mt-7 mb-4 leading-7">Training sessions</h3>
              <p className="leading-[1.625rem]">
                Practical training and learning sessions from mentors and industry experts
              </p>
            </li>
            <li className="md:mb-0 mb-5 w-full md:max-w-[calc(58.3%-0.938rem)] max-w-full lg:p-12 py-8 px-5 bg-[#F8F7F2] flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              {/* <StaticImage
                src="../assets/img/bevrages.inline.svg"
                alt="Bevrages icon"
                placeholder="blurred"
              /> */}
              <Beverages />
              <h3 className="btch-big bg-[#FFDBCA] inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mt-7 mb-4 leading-7">Bountiful beverages</h3>
              <p className="leading-[1.625rem]">
                At Techuz, drinking beverages is our part-time job. Some regard them as their fuel, while others call them ambrosia. In fact, if beverages were a currency, we would happily accept them as payment. If you have the same passion for the drinks, you’re destined to be on our team. But before that, choose your poison: tea or coffee?
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section className="lg:pb-[6.875rem] pb-[4rem]">
        <div className="container">
          <div className="title-sec m-auto w-full max-w-[34.688rem] pb-10 text-center">
            <h3 className="btch-big bg-cream inline-block pt-[0.375rem] pb-[0.125rem] px-[1.5rem] rounded-[6.25rem] text-base text-center italic font-gorditamedium mb-[1.875rem] leading-7">Leader’s outlook</h3>
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center">
              Here is what our team says
              <span className="inline-block text-orangecol">.</span>
            </h2>
          </div>
          <ul className="flex justify-between flex-wrap">
            <li className="md:mb-[1.875rem] mb-5 w-full md:max-w-[calc(50%-0.938rem)] max-w-full p-6 md:p-10 md:pb-[1.875rem] border border-white-800 flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              <p className="leading-[1.875rem]">
                “I am working at Techuz InfoWeb since last 3 years and trust me it is one of the wonderful experience being part of such an amazing and growing company, very encouraging, satisfied work culture makes it different than other companies, i love to be part of Techuz InfoWeb.”
              </p>
              <div className="flex lg:items-center items-start max-[992px]:flex-col justify-between  pt-[1.875rem] w-full">
                <div className="flex items-center">
                  <div className="bg-[#D9D9D9] w-[3.75rem] min-w-[3.75rem] h-[3.75rem] border border-[#CDCAF4] rounded-full">

                    <StaticImage
                      src="../assets/img/joy.webp"
                      alt="Author"
                      className="min-w-full w-full h-full rounded-full object-[0px_5px]"
                      placeholder="blurred"
                    />
                  </div>
                  <div className="flex flex-col pl-5">
                    <h3 className="font-gorditamedium text-black text-lg">
                      Joy Zalte
                    </h3>
                    <p
                      className="text-sm "
                    >
                      Backend Developer
                    </p>
                  </div>
                </div>
                <div className="inline-flex items-center bg-[#F8F7F2] justify-start rounded-full pt-2 pb-2 px-4 font-gorditamedium text-sm leading-none max-[992px]:ml-[80px]">
                  <StaticImage
                    src="../assets/img/star-y.png"
                    alt="Star-y icon"
                    placeholder="blurred"
                  />
                  <span className="mr-2 ml-1 relative top-[2px] text-sm font-gorditamedium">5.0/5</span>
                  <StaticImage
                    src="../assets/img/google.png"
                    alt="Google icon"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </li>
            <li className="md:mb-[1.875rem] mb-5 w-full md:max-w-[calc(50%-0.938rem)] max-w-full p-6 md:p-10 md:pb-[1.875rem] border border-white-800 flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              <p className="leading-[1.875rem]">
                “Thriving environment! The team's fantastic, making collaboration a breeze. The office itself reflects the awesome culture here. It's no wonder it's a top workplace for growth. Reliable company policies and a great office space are the icing on the cake.”
              </p>
              <div className="flex lg:items-center items-start max-[992px]:flex-col justify-between  pt-[1.875rem] w-full">
                <div className="flex items-center">
                  <div className="bg-[#D9D9D9] w-[3.75rem] min-w-[3.75rem] h-[3.75rem] border border-[#CDCAF4] rounded-full">

                    <StaticImage
                      src="../assets/img/brijesh-kavar.webp"
                      alt="Author"
                      className="min-w-full w-full h-full rounded-full"
                      placeholder="blurred"
                    />
                  </div>
                  <div className="flex flex-col pl-5">
                    <h3 className="font-gorditamedium text-black text-lg">
                      Brijesh Kavar
                    </h3>
                    <p
                      className="text-sm "
                    >
                      Full Stack Developer
                    </p>
                  </div>
                </div>
                <div className="inline-flex items-center bg-[#F8F7F2] justify-start rounded-full pt-2 pb-2 px-4 font-gorditamedium text-sm leading-none max-[992px]:ml-[80px]">
                  <StaticImage
                    src="../assets/img/star-y.png"
                    alt="Star-y icon"
                    placeholder="blurred"
                  />
                  <span className="mr-2 ml-1 relative top-[2px] text-sm font-gorditamedium">5.0/5</span>
                  <StaticImage
                    src="../assets/img/google.png"
                    alt="Google icon"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </li>
            <li className="md:mb-0 mb-[1.875rem] w-full md:max-w-[calc(50%-0.938rem)] max-w-full p-6 md:p-10 md:pb-[1.875rem] border border-white-800 flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              <p className="leading-[1.875rem]">
                “Thriving in a chill work environment with an incredibly supportive team. Appreciate the freedom and lack of micromanagement, making it a fantastic place to grow and excel!”
              </p>
              <div className="flex lg:items-center items-start max-[992px]:flex-col justify-between  pt-[1.875rem] w-full">
                <div className="flex items-center">
                  <div className="bg-[#D9D9D9] w-[3.75rem] min-w-[3.75rem] h-[3.75rem] border border-[#CDCAF4] rounded-full">

                    <StaticImage
                      src="../assets/img/shefali.webp"
                      alt="Auhtor"
                      className="min-w-full w-full h-full rounded-full"
                      placeholder="blurred"
                    />
                  </div>
                  <div className="flex flex-col pl-5">
                    <h3 className="font-gorditamedium text-black text-lg">
                      Shefali Patel
                    </h3>
                    <p
                      className="text-sm "
                    >
                      UI/UX Designer
                    </p>
                  </div>
                </div>
                <div className="inline-flex items-center bg-[#F8F7F2] justify-start rounded-full pt-2 pb-2 px-4 font-gorditamedium text-sm leading-none max-[992px]:ml-[80px]">
                  <StaticImage
                    src="../assets/img/star-y.png"
                    alt="Star-y icon"
                    placeholder="blurred"
                  />
                  <span className="mr-2 ml-1 relative top-[2px] text-sm font-gorditamedium">5.0/5</span>
                  <StaticImage
                    src="../assets/img/google.png"
                    alt="Google icon"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </li>
            <li className="md:mb-0 w-full md:max-w-[calc(50%-0.938rem)] max-w-full p-6 md:p-10 md:pb-[1.875rem] border border-white-800 flex flex-col text-left rounded-[1.25rem] justify-start items-start">
              <p className="leading-[1.875rem]">
                “A fantastic web development company! It boasts a friendly atmosphere, supportive staff, and liberal policies, making it a joy to work there for the past 5+ years.  Truly a great place!”
              </p>
              <div className="flex lg:items-center items-start max-[992px]:flex-col justify-between  pt-[1.875rem] w-full">
                <div className="flex items-center">
                  <div className="bg-[#D9D9D9] w-[3.75rem] min-w-[3.75rem] h-[3.75rem] border border-[#CDCAF4] rounded-full">

                    <StaticImage
                      src="../assets/img/nilesh-ppc.webp"
                      alt="Author"
                      className="min-w-full w-full h-full rounded-full"
                      placeholder="blurred"
                    />
                  </div>
                  <div className="flex flex-col pl-5">
                    <h3 className="font-gorditamedium text-black text-lg">
                      Nilesh Kadivar
                    </h3>
                    <p
                      className="text-sm "
                    >
                      Marketing Lead
                    </p>
                  </div>
                </div>
                <div className="inline-flex items-center bg-[#F8F7F2] justify-start rounded-full pt-2 pb-2 px-4 font-gorditamedium text-sm leading-none max-[992px]:ml-[80px]">
                  <StaticImage
                    src="../assets/img/star-y.png"
                    alt="Star-y icon"
                    placeholder="blurred"
                  />
                  <span className="mr-2 ml-1 relative top-[2px] text-sm font-gorditamedium">5.0/5</span>
                  <StaticImage
                    src="../assets/img/google.png"
                    alt="Google icon"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="lg:pb-[6.875rem] pb-[4rem]">
        <div className="container">
          <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] bg-[url('../assets/img/tech-bg.webp')] rounded-[1.25rem] overflow-hidden">
            <div className="flex justify-between items-start flex-col">
              <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full leading-9 md:leading-[2.938rem] pb-3">Want to <span className="text-[#4BD965] italic">apply for a position</span> that isn't listed?</h2>
              <p>Send your resume and we’ll get in touch with you as soon as we have a vacancy.</p>
            </div>
            <Link
              to="mailto:careers@techuz.com"
              className="inline-flex items-center bg-white-200 justify-start inline-white text-black text-base rounded-3xl pl-6 pt-[12px] pb-[10px] relative pr-[52px] leading-[1.313rem] font-gorditamedium group ease-in duration-300 shadow-1xl"
            >
              Mail Us
              <span className="inline-flex bg-primary justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent absolute right-[5px] top-[5px] group-hover:border-primary">
                <img
                  src={ArrowWhite}
                  alt="ArrowRight"
                  className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                />
                <img
                  src={ArrowWhite}
                  alt="ArrowRight"
                  className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                />
              </span>
            </Link>
          </div>
        </div>
      </section>
      <Contact />
    </>
  )
}

export default CareerPage

export const Head: HeadFC = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <SEO
      title="Join Team Techuz – Find Jobs in Design and Development, Sales and QA"
      canonical={`${siteUrl}${location.pathname}`}
      description="Are you experienced in developing web or mobile applications using latest frameworks and languages and looking to give new heights to your career? We are hiring, join our team!"
    />
  )
}
