import { graphql, HeadFC, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Contact from "../components/Contact"
import SEO from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"
import EcoSystem from "../components/EcoSystemComponent"
import animatedIcon from "../assets/img/Contact-us-animation4.gif"
import animatedIconWhite from "../assets/img/Contact-us-animation-white.gif"
const PortfolioDetailPage = props => {
  const {
    data,
    pageContext: { next },
  } = props
  const { post } = data
  post.heroImage = getImage(post.heroImage)
  post.innerLogo = getImage(post.innerLogo)
  post.wireframeImage = getImage(post.wireframeImage)
  next.heroImage = getImage(next.heroImage)

  return (
    <>
      <section className="">
        <div className={`w-full ${post.backgroundStyle} pt-[7.5rem] text-center`}>
          <div className="w-full title-sec pb-[3.125rem]">
            <h2 className="text-black text-[30px] md:text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium text-center pb-5 lg:max-w-[1080px] mx-auto px-5 md:px-0">
              {post.title}
            </h2>
            {/* <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem] max-w-[50rem] mx-auto my-0">{post.subTitle}</p> */}
          </div>
          <div className="px-2 md:px-0">
            <GatsbyImage
              image={post.heroImage}
              alt={post.heroImageAlt}
              className="w-full h-auto flex flex-col justify-center items-center m-auto  xl:max-w-[60%] drop-shadow-[(0px_24px_51px_rgba(142,175,153,0.26))] rounded-t-[20px]"
              loading="eager"
            />
          </div>

        </div>
        <div className="lg:py-[3.438rem] md:py-20 py-10">
          <div className="container">
            <div className="title-sec m-auto w-full max-w-[52.5rem] pb-10">
              <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium text-center">
                Project brief
                <span className="inline-block text-orangecol">.</span>
              </h2>
              {post.description.map((paragraph, idx) => (
                <p key={idx} className="text-base lg:text-[1.125rem] lg:leading-[1.875rem] text-center mt-5">
                  {paragraph}
                </p>
              ))}
            </div>
            <div className="w-full">
              <ul className="grid md:grid-cols-[13.75rem_1fr] gap-5 max-w-[58.75rem] w-full m-auto lg:p-0 px-4">
                <li className="w-full mb-5 lg:mb-0 rounded-[0.625rem] p-6 flex justify-between md:flex-col items-start md:justify-start bg-[#F8F7F3]">
                  <div className="text-left">
                    <h5 className="btch-big bg-[#E8E3FF] inline-block py-[0.313rem] pt-[0.438rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mb-3">
                      Budget
                    </h5>
                    <h4 className="text-base font-gorditaregular">
                      {post.tags["budget"]}
                    </h4>
                  </div>
                  <div className="text-left md:mt-11">
                    <h5 className="btch-big bg-[#FFDBCA] inline-block py-[0.313rem] px-[1.5rem] pt-[0.438rem] pr-[0.75rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mb-3">
                      Duration
                      <span className="bg-orangecol w-1 h-21 rounded-full inline-block ml-1"></span>
                    </h5>
                    <h4 className="text-base font-gorditaregular">
                      {post.tags["duration"]}
                    </h4>
                  </div>
                </li>
                {/* <li className="lg:max-w-[calc(20%-0.625rem)] md:max-w-[calc(50%-0.625rem)] w-full mb-5 lg:mb-0 rounded-[0.625rem] p-6 flex flex-col items-center justify-start bg-[#F8F7F3]">
                  <div className="text-left">
                    <h5 className="btch-big bg-[#FFDBCA] inline-block py-[0.313rem] px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mb-3">
                      duration
                      <span className="bg-orangecol w-1 h-21 rounded-full inline-block ml-1"></span>
                    </h5>
                    <h4 className="text-base font-gorditaregular">
                      {post.tags["duration"]}
                    </h4>
                  </div>
                </li> */}
                <li className="w-full rounded-[1.25rem] p-6 flex flex-col items-start justify-start border border-cream">
                  <div className="text-left">
                    <h5 className="btch-big bg-[#FFF5C6] inline-block py-[0.313rem] pt-2 px-[1.5rem] rounded-[6.25rem] text-sm text-center italic font-gorditamedium mb-3">
                      Deliverables
                    </h5>
                    <ul className="flex flex-wrap">
                      {post.tags["deliverables"].map((value, idx) => (
                        <li
                          key={idx}
                          className="text-xs text-black pt-2 mt-2 font-gorditaregular px-3 py-2 bg-white-300 mr-2 rounded capitalize"
                        >
                          {value}
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
              {post.url && (
                <div className="text-center pt-10">
                  <Link
                    target="_blank"
                    to={post.url}
                    className="inline-flex items-center bg-white-200 justify-between inline-white text-black border border-black shadow-1xl text-base m-auto rounded-3xl pl-6 py-[0.313rem] pr-[0.313rem] font-gorditamedium leading-[1.313rem] hover:bg-transparent hover:border-primary group"
                  >
                    Visit Website
                    <span className="inline-flex w-[1.875rem] h-[1.875rem] bg-primary border-2 border-primary justify-center items-center rounded-full ml-[0.625rem] ease-in duration-300 group-hover:bg-transparent relative">
                      <img
                        src={animatedIconWhite}
                        alt=""
                        className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 visible group-hover:opacity-0 group-hover:invisible transition-all ease-in duration-300"
                      />
                      <img
                        src={animatedIcon}
                        alt=""
                        className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all ease-in duration-300"
                      />
                    </span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <EcoSystem ecosystem={post.ecosystem} />

      {post.challenges && <section className="lg:py-[3.438rem] md:py-10 py-10">
        <div className="container">
          <div className="title-sec text-center md:max-w-[42.813rem] mx-auto mb-[2.313rem] lg:mb-[4.375rem]">
            <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Describe overall <span className="italic text-primary">role of Techuz team</span> in this project?</h2>
          </div>

          <div className="before:clear-both before:content-[''] before:block after:content-[''] after:block after:clear-both ">
            <div className="lg:float-left lg:max-w-[27.313rem] lg:sticky md:top-24 md:left-0">
              <h4 className="text-black text-[30px] lg:text-[2.625rem] leading-[2.938rem] pb-6 font-blancomedium lg:max-w-[23.75rem]">Challenges & <span className="italic text-primary">Solutions</span></h4>
              <p className="text-base leading-[1.625rem]">{post.challenges.description}</p>
            </div>
            <div className="lg:float-right max-md:mt-10 lg:max-w-[calc(100%-27.313rem)] lg:pl-20">
              <div className="grid grid-cols-1 gap-y-[2.5rem]">
                {post.challenges.points.map(({ title, description }, idx) => (
                  <div key={idx}>
                    <span className="flex flex-wrap items-center justify-center text-2xl rounded-full border border-black font-blancomedium w-[3.75rem] h-[3.75rem] mb-[30px]">{idx + 1}</span>
                    {/* <span className={`inline-block italic py-2 px-[1.125rem] pb-[0.375rem] md:px-6 rounded-[6.25rem] ${title[0]} text-xs md:text-sm lg:text-base mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]`}>{title[1]}</span> */}
                    <h3 className="font-blancomedium text-2xl leading-[2.188rem]">{title}</h3>
                    {description.map((paragraph, idx) => (
                      <p key={idx} className="text-base leading-[1.625rem] mt-5">{paragraph}</p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>}

      <section className="resource-utilized lg:py-[3.438rem] md:py-10 py-10">
        <div className="container">
          <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center max-w-[42.75rem] mx-auto my-0 mb-10">
            <span className="italic text-primary">Resources utilised</span> to develop this project <span className="inline-block text-orangecol">.</span>
          </h2>
          <ul className="resource-list flex flex-wrap items-center justify-center gap-5">
            {post.resources.map(({ resourceImage, text, totalResource }, idx) => (
              <li key={`resource-${idx}`} className="p-[1.375rem] px-2 md:p-[1.375rem] border bottom-[0.125rem] border-[#FFF2E3] rounded-[1.25rem] text-center w-[calc(50%-10px)] md:w-[40%] lg:w-[18%]">
                <div className="img-container mb-[0.938rem]">
                  <img className="mx-auto my-0" loading="lazy" src={resourceImage.publicURL} alt={`${text} icon`} />
                </div>
                <p className="text-black text-sm font-gorditamedium leading-6 mb-1">{text}</p>
                <span className="inline-block text-[#979797] text-2xl font-gorditamedium">{totalResource}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="core-features lg:pt-[3.438rem] lg:pb-[110px] md:py-20 py-10">
        <div className="container">
          <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center max-w-[42.75rem] mx-auto my-0 mb-10">
            Core Features<span className="inline-block text-orangecol">.</span>
          </h2>
          <ul className="md:max-w-[50.625rem] mx-auto my-0 grid md:grid-cols-2 gap-5">
            {
              post.core_features.map((feature, idx) => (
                <li key={`feature-${idx}`} className="text-lg leading-7 md:mb-[0.625rem] relative pl-[1.625rem] inline-block"> <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-[1.125rem] w-[1.125rem] absolute top-[0.188rem] left-0"></span>{feature}</li>
              ))
            }
          </ul>
        </div>
      </section>

      {/* {post.owner_quote &&
        <section className="lg:py-[3.438rem] md:py-20 py-10">
          <div className="container">
            <div className="flex max-w-[59.375rem] flex-wrap gap-y-6 w-full m-auto justify-center ">
              <div className="bg-[linear-gradient(90deg,rgba(233,250,248,1),rgba(225,251,218,1))] flex flex-wrap items-center justify-center md:w-[16.563rem] rounded-[1.25rem]">
                <GatsbyImage
                  image={post.innerLogo}
                  alt={post.heroImageAlt}
                  className="w-full h-auto max-w-[150px]"
                />
              </div>
              <div className="comp-list-testimonial max-w-[40.938rem] border border-black rounded-[1.25rem] p-[1.875rem] my-0 mx-auto">
                <div className="item flex flex-wrap md:flex-nowrap items-center relative">
                  <div className="item-content">
                    <p className="text-base md:text-[1.375rem] md:leading-8">“{post.owner_quote}”</p>
                    <div className="item flex flex-wrap md:flex-nowrap items-center relative pt-8">
                      <h6 className="text-lg mt-[0.438rem]"><strong className="font-gorditamedium">{post.owner_detail[0]} -</strong> {post.owner_detail[0]}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>} */}

      <section className="bg-[#F8F7F3] lg:pt-[3.125rem] md:pt-10 pt-10">
        <div className="container">
          <div className="title-sec m-auto w-full lg:pb-[3.125rem] md:pb-20 pb-10">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center">
              <span className="italic text-primary">Glimpse</span> of the project
            </h2>
          </div>
        </div>
        {post.web_mockup_images && (
          <div className="md:bg-gradient-to-t to-[rgba(252,252,253,0)] from-[#F5F5FB]">
            <div className="container">
              <div className="flex flex-wrap justify-between items-end">
                {post.web_mockup_images?.map((image, idx) => {
                  image = getImage(image)
                  return (
                    <div
                      key={idx}
                      className={`${post.web_mockup_images.length === 2
                        ? "md:w-[calc(50%-0.938rem)]"
                        : "md:w-[calc(33.333333%-0.938rem)]"
                        } w-full md:text-left text-center ${idx !== 3 && "md:mb-0 mb-7"
                        }`}
                    >
                      <GatsbyImage
                        image={image}
                        alt={`${post.title} designs`}
                        className="md:w-full w-10/12 mx-auto shadow-sm"
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {post.tablet_mockup_images && (
          <div className="md:bg-gradient-to-t to-[rgba(252,252,253,0)] from-[#F5F5FB] pt-20">
            <div className="container">
              <div className="flex flex-wrap justify-between items-end gap-x-[34px] gap-y-[39px]">
                {post.tablet_mockup_images?.map((image, idx) => {
                  image = getImage(image)
                  return (
                    <div
                      key={idx}
                      className={`${post.tablet_mockup_images.length === 2
                        ? "md:w-[48%]"
                        : "md:w-[48%]"
                        } w-full md:text-left text-center ${idx !== 3 && "md:mb-0 mb-7"
                        }`}
                    >
                      <GatsbyImage
                        image={image}
                        alt={`${post.title} designs`}
                        className="md:w-full mx-auto"
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {post.wireframeImage &&
          <div className="pt-[3.75rem] bg-white-200">
            <div className="container">
              <GatsbyImage
                image={getImage(post.wireframeImage)!}
                alt={`${post.title} designs`}
                className="md:w-full mx-auto"
              />
            </div>
          </div>
        }
      </section>



      {post.mobile_mockup_images && (
        <section className="md:pt-20 pt-10 lg:pb-[3.438rem] md:pb-20 pb-10">
          <div className="md:bg-gradient-to-t to-[rgba(252,252,253,0)] from-[#F5F5FB]">
            <div className="container">
              <div className="flex flex-wrap justify-between items-end">
                {post.mobile_mockup_images?.map((image, idx) => {
                  image = getImage(image)
                  return (
                    <div
                      key={idx}
                      className="md:w-[calc(33.333333%-0.938rem)] w-full md:mb-0 mb-7 md:text-left text-center"
                    >
                      <GatsbyImage
                        image={image}
                        alt={`${post.title} designs`}
                        className="md:w-full w-10/12 mx-auto"
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      )}
      {next &&
        <Link to={`/portfolio${next.slug}/`}>
          <section className={`md:pt-20 pt-10 ${next.backgroundStyle}`}>
            <div className="container">
              <div className="title-sec m-auto w-full">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <div
                      className="flex items-center text-[1.375rem] mx-10 text-black font-gorditamedium hover:text-primary transition-all"
                    >
                      View Next
                    </div>
                  </div>
                  <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center underline mb-[50px]">
                    {next.title}
                  </h2>
                  <GatsbyImage
                    image={next.heroImage}
                    alt={`${next.title} banner`}
                    className="w-full rounded-[1.875rem drop-shadow-[0_0_20px_rgba(0,0,0,0.1)]]"
                  />
                </div>
              </div>
            </div>

          </section>
        </Link>
      }

      <Contact />
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    post: portfolioJson(slug: { eq: $slug }) {
      seo_title
      seo_description
      categories
      field
      slug
      url
      title
      subTitle
      backgroundStyle
      core_features
      resources {
        resourceImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          publicURL
        }
        text
        totalResource
      }
      technologies {
        name
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          publicURL
          extension
        }
        alt
      }
      description
      heroImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      innerLogo {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      owner_quote
      owner_detail
      heroImageAlt
      tags {
        budget
        duration
        deliverables
      }
      url
      web_mockup_images {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      tablet_mockup_images {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      mobile_mockup_images {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      wireframeImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      ecosystem {
        title
        left_div {
            title
            children {
                text
                techImage {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    publicURL
                    extension
                }
            }
        }
        right_div {
            title
            children {
                text
                techImage {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    publicURL
                    extension
                }
            }
        }
      }
      challenges {
        description
        points {
          title
          description
        }
      }
    }
  }
`
export default PortfolioDetailPage

export const Head: HeadFC = ({ location, data }) => {
  const { siteUrl } = useSiteMetadata()
  const { seo_title, seo_description } = data["post"]
  return (
    <SEO
      title={seo_title}
      canonical={`${siteUrl}${location.pathname}`}
      description={seo_description}
    />
  )
}
